<div class="container2" [hidden]="loadPage">
    <div class="flex2">
        <div class="loader2">
        </div>
    </div>
    <div class="load-text2">
        <span> </span>
    </div>
</div>
<ng-container *ngIf="showDataPanle">
    <mat-card class="example-card">
        <mat-card-header>
            <mat-card-title>{{'company.TitlePage' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="margin-top: -20px;">
                <div class="col-lg-12">
                    <div class="row" style="margin-bottom: 30;margin-top: 20px;">
                        <div class="col-lg-8">
                            <div class="row">

                                <div class="col-lg-6 col-sm-12 col-xs-12">

                                    <mat-form-field appearance="fill" style="  width: 100%;
                          margin: 0;
                          margin-right: 10px;">
                                        <mat-label>{{'company.Lang' | translate}}</mat-label>
                                        <mat-select matNativeControl [disabled]="disableSelect" [(value)]="selectedLang">
                                            <mat-option *ngFor="let Lang of languages" [value]="Lang.LangId">
                                                {{Lang.LangName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-sm-12 col-xs-12">
                                    <mat-form-field appearance="fill" style="    width: 100%;
                          margin: 0;
                          margin-right: 10px;">
                                        <mat-label>{{'company.status' | translate}}</mat-label>
                                        <mat-select [(value)]="selected">
                                            <mat-option *ngFor="let Statu of Status" [value]="Statu.IntStatId">
                                                {{Statu.StatNameAr}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-12 col-sm-12 col-xs-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{'company.Name' | translate}} </mat-label>
                                        <input autocomplete="off" [(ngModel)]="ComName" matInput>
                                        <mat-icon style="color: #374bab;" matSuffix>edit</mat-icon>
                                    </mat-form-field>

                                </div>

                            </div>

                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-xs-12">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{'company.active' | translate}} </mat-label>
                                        <input [(ngModel)]="ComActivity" matInput>
                                        <mat-icon style="color: #374bab;" matSuffix>edit</mat-icon>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">

                                <mat-tab-group animationDuration="1000ms" style="margin-top: 4px;">
                                    <mat-tab label="{{'company.img1' | translate}}">

                                        <div class="col-lg-12 col-sm-12 col-xs-12" style="text-align: center;    margin-top: 10px;">

                                            <input hidden accept="image/*" (change)="fileChangeEvent($event)" #fileInput type="file" id="file">

                                            <img [src]="croppedImage" (click)="fileInput.click()" width="200" height="200" />

                                        </div>
                                    </mat-tab>
                                    <mat-tab label="{{'company.img2' | translate}}">
                                        <div class="col-lg-12 col-sm-12 col-xs-12" style="text-align: center;    margin-top: 10px;">

                                            <input hidden accept="image/*" (change)="fileChangeEvent2($event)" #fileInput2 type="file" id="file">

                                            <img [src]="croppedImage2" (click)="fileInput2.click()" width="200" height="200" />

                                        </div>
                                    </mat-tab>
                                </mat-tab-group>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>

            <p style="text-align: center;">

                <button *ngIf="showUpdate" style="margin-left: 10px;" class="btn_float" mat-fab color="primary" (click)="update()">{{'btn.update' | translate}}</button>

                <button *ngIf="showcancel" matTooltipPosition="left" matTooltip="الغاء العملية" class="floatcancel" (click)="btnclose()" mat-fab color="primary">{{'btn.close' | translate}} </button>

            </p>
        </mat-card-actions>
    </mat-card>
</ng-container>

<mat-card class="example-card" style="margin-top: 10px;" [hidden]="showTableCom">
    <mat-card-header>
        <mat-card-title>{{'company.ShowPage' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div style="margin: 20px;">


            <p style="text-align: center;">


                {{'btn.show' | translate}}
                <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
            </p>

            <table style="width: 100%;
            max-width: 100%;
            margin-bottom: 1.143rem;
            background-color: transparent;" class="table table-bordered  table-striped">
                <thead>
                    <tr>
                        <th style="padding: 3px;text-align: center;" scope="col">#</th>
                        <th style="padding: 3px;text-align: center;" scope="col">{{'company.Name' | translate}}</th>
                        <th style="padding: 3px;text-align: center;" scope="col">{{'company.status' | translate}}</th>
                        <th style="padding: 3px;text-align: center;" scope="col">{{'company.Lang' | translate}}</th>
                        <th style="padding: 3px;text-align: center;" scope="col">{{'slider.Processes' | translate}}</th>
                    </tr>
                </thead>
                <tbody style="text-align: center!important;">
                    <tr *ngFor="let item of tableCom;let i = index">
                        <th style="padding: 3px;" scope="row">{{i+1}}</th>
                        <td style="padding: 3px;">{{item.comName}}</td>
                        <td style="padding: 3px;">{{item.namestatAr}}</td>
                        <td style="padding: 3px;">{{item.langId}}</td>
                        <td style="padding: 3px;width: 150px;">
                            <button style="margin-left: 3px;" matTooltipPosition="above" matTooltip="تعديل" (click)="showData(item.fcomId,item.langId)" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                <mat-icon>edit</mat-icon>
              </button>

                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li *ngFor="let item of pageNumbers" class="page-item"><button (click)="getRows(item.numPage)" class="page-link">{{item.namePage}}</button></li>
                </ul>
            </nav>
        </div>
    </mat-card-content>
</mat-card>

<ng-container>
    <div [hidden]="showImage" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="padding-right: 17px; display:block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLiveLabel">اقتصاص الصورة</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
                </div>
                <div class="modal-body">
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 2" [resizeToWidth]="1000" [cropperMinWidth]="1000" [cropperMinWidth]="500" [cropperMinHeight]="500" [canvasRotation]="canvasRotation" [onlyScaleDown]="true"
                        [imageURL]="imgUrl" format="jpeg" [imageQuality]="30" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-primary" (click)="cut()">اقتصاص</button>
                    <button type="button" class="btn btn-secondary" (click)="cut()" data-dismiss="modal">الغاء</button>
                </div>
            </div>
        </div>
    </div>

</ng-container>


<ng-container>
    <div [hidden]="showImage2" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="padding-right: 17px; display:block;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLiveLabel">اقتصاص الصورة</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
                </div>
                <div class="modal-body">
                    <image-cropper [imageChangedEvent]="imageChangedEvent2" [maintainAspectRatio]="true" [aspectRatio]="4 / 2" [resizeToWidth]="1000" [cropperMinWidth]="1000" [cropperMinWidth]="500" [cropperMinHeight]="500" [canvasRotation]="canvasRotation2" [onlyScaleDown]="true"
                        [imageURL]="imgUrl2" format="jpeg" [imageQuality]="30" (imageCropped)="imageCropped2($event)" (imageLoaded)="imageLoaded2()" (cropperReady)="cropperReady2()" (loadImageFailed)="loadImageFailed2()">
                    </image-cropper>
                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-primary" (click)="cut2()">اقتصاص</button>
                    <button type="button" class="btn btn-secondary" (click)="cut2()" data-dismiss="modal">الغاء</button>
                </div>
            </div>
        </div>
    </div>

</ng-container>