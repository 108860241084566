import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
})
export class EmailComponent implements OnInit {
  showTableNews: boolean = false;
  loadPage: boolean = false;
  UserEmail: string = '';
  showSave: boolean = false;
  showcancel: boolean = false;
  showUpdate: boolean = true;
  showDataPanle;
  emailAddress = '';
  emailPass = '';
  Port = '';
  Host = '';
  tableNews:any=[]

  btnNew() {
    this.showcancel = true;
    this.showDataPanle = true;
    this.showUpdate = false;
    this.showTableNews = true;
    this.clearData();
  }
  btnclose() {
    this.showcancel = false;
    this.showDataPanle = false;
    this.showUpdate = false;
    this.clearData();
    this.showTableNews = false;
  }
  btnUpdate() {
    this.showDataPanle = true;
    this.showSave = false;
    this.showcancel = true;
    this.showUpdate = true;

    this.showTableNews = true;
  }

  clearData(): void {
    this.emailAddress=''
    this.emailPass=''
    this.Port=''
    this.Host=''
  }

  showData(): void {
    this.loadPage = false;
    this.email.CompanyPost
    (
      'semail', '', '',
      '', '', '',
      '', '', '','','','',''
    ).subscribe(data => {
      console.log("email",data);
      
      this.emailAddress = data[0]['comEmail']
      this.emailPass = data[0]['comEmailPass']
      this.Port = data[0]['comEmailPort']
      this.Host = data[0]['comEmailHost']
      
    this.loadPage = true;
    });
    this.btnUpdate();
  }

  update() {
    this.loadPage = false;
    this.email.CompanyPost
    (
      'uemail','','','','','','','','',this.emailAddress,this.emailPass,this.Port,this.Host
    ).subscribe(data => {
   this._snackBar.open(data[0]["column1"], 'حسنا', {
    duration: 5000,
    horizontalPosition: 'left'
  });
    this.loadPage = true;
    this.showDataPanle = false;
    this.showTableNews = false;
    this.clearData();
    this.showTableData();
    });
  }

  constructor(
    private email: CompanyService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadPage = false;
this.showTableData();
   
    this.loadPage = true;
  }
  showTableData(){
    this.email.CompanyPost
    (
      'semail', '', '',
      '', '', '',
      '', '', '','','','',''
    ).subscribe(data => {
      this.tableNews=data;
      console.log("email",this.tableNews);
      
      
    })
  }
}
