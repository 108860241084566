import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
     
    <input  (input)="onSearchChange($event.target.value)"  [attr.type]="field.type" class="form-control d-none"  [id]="field.name" [name]="field.name" [formControl]="form">
    <input type="checkbox"  [checked]="checked" (change)="onCheckboxChange($event)"   />
    `
})
export class CheckBoxComponent{
    @Input() field:any = {};
    @Input() form:UntypedFormControl;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; }
  
    checked:boolean
    constructor() { 
    }

    onSearchChange(Value: string): void {  

        if(Value == 'true' || Value == '1')
            this.checked = true
        else if(Value == 'false' || Value == '0')
        this.checked = false
        else
        this.checked = false

      }

    onCheckboxChange(e)
    {
        if(this.checked == true)
        new UntypedFormControl(e.target.value)
        else
        new UntypedFormControl(e.target.value)
    }
}