<div class="container2" [hidden]="loadPage">
    <div class="flex2">
      <div class="loader2"></div>
    </div>
    <div class="load-text2">
      <span> </span>
    </div>
  </div>
  
  <ng-container *ngIf="showDataPanle">
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>{{ "Email.email" | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div style="margin-top: -20px">
          <div class="col-lg-12">
            <div class="row" style="margin-bottom: 30; margin-top: 20px">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label> {{ "Email.emailTitle" | translate }}</mat-label>
                      <input
                        [(ngModel)]="emailAddress"
                        autocomplete="off"
                        type="email"
                        matInput
                        placeholder="{{'Email.emailTitle' | translate}}"
                      />
                      <mat-icon style="color: #374bab" matSuffix>email</mat-icon>
                    </mat-form-field>
                  </div>
  
                  <div class="col-lg-6 col-sm-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label> {{ 'Email.emailPass' | translate }}</mat-label>
                      <input
                        [(ngModel)]="emailPass"
                        autocomplete="off"
                        type="password"
                        matInput
                        placeholder="{{'Email.emailPass' | translate}}"
                      />
                      <mat-icon style="color: #374bab" matSuffix
                        >password</mat-icon
                      >
                    </mat-form-field>
                  </div>
  
                  <div class="col-lg-6 col-sm-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{ "Email.emailPort" | translate }}</mat-label>
                      <input
                        [(ngModel)]="Port"
                        autocomplete="off"
                        type="text"
                        matInput
                        placeholder="587"
                      />
                      <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                    </mat-form-field>
                  </div>
  
                  <div class="col-lg-6 col-sm-12 col-xs-12">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{ "Email.emailHost" | translate }}</mat-label>
                      <input
                        [(ngModel)]="Host"
                        autocomplete="off"
                        type="text"
                        matInput
                        placeholder="{{'Email.emailHost' | translate}}"
                      />
                      <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <p style="text-align: center">  
          <button
            *ngIf="showUpdate"
            style="margin-left: 10px"
            class="btn_float"
            mat-fab
            color="primary"
            (click)="update()"
          >
            تعديل
          </button>
  
          <button
            matTooltipPosition="left"
            matTooltip="الغاء العملية"
            class="floatcancel"
            (click)="btnclose()"
            mat-fab
            color="primary"
          >
            الغاء
          </button>
        </p>
      </mat-card-actions>
    </mat-card>
  </ng-container>
  
  <mat-card
    class="example-card"
    style="margin-top: 10px"
    [hidden]="showTableNews"
  >
    <mat-card-header>
      <mat-card-title> {{ "Email.email" | translate }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="margin: 20px">
  
        <table
          style="
            width: 100%;
            max-width: 100%;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="padding: 3px; text-align: center" scope="col">#</th>
  
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "Email.emailTitle" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "Email.emailPort" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "Email.emailHost" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "branches.Processes" | translate }}
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center !important">
            <tr *ngFor="let item of tableNews; let i = index">
              <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
              <td style="padding: 3px">{{ item.comEmail }}</td>
              <td style="padding: 3px">{{ item.comEmailPort }}</td>
              <td style="padding: 3px">{{ item.comEmailHost }}</td>
  
              <td style="padding: 3px; width: 150px">
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="تعديل"
                  (click)="showData(item.usrId)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
  