import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servicesUrl } from '../servicesUrl'
import { IBrnName, Icompany, IcompanyStatus } from 'src/app/components/company/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class users {

  constructor(private http: HttpClient, private urlApi: servicesUrl) { }
  
  public imageUrl(): string {
    return this.urlApi.url + '/images/users/'
  }

  public getUsersWhereId(id): Observable<Icompany[]> {
    return this.http.get<Icompany[]>(this.urlApi.url + '/users/' + id );
  }

  

  public getUsersStatus(lang): Observable<IcompanyStatus[]> {
    return this.http.get<IcompanyStatus[]>(this.urlApi.url + '/users' + '/UsersStatus/'+lang);
  }

  public GetUsersbrnName(lang): Observable<IBrnName[]> {
    return this.http.get<IBrnName[]>(this.urlApi.url + '/users' + '/GetUsersbrnName/'+lang);
  }


  public viewUsersPaging(pageIndex, ddl,LANG,UserID) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('LANG', LANG);
    formData.append('ddl', ddl);
    formData.append('UserId', UserID);
    return this.http.post(this.urlApi.url + '/Users/pagging', formData);
  }

  public UsersPost(check, UserId, UserName, UserPass, UserFullName, UserEmail, UserMobile, FbrnId,FUserId,Fstate,userImage) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('UserId', UserId);
    formData.append('UserName', UserName);
    formData.append('UserPass', UserPass);
    formData.append('UserFullName', UserFullName);
    formData.append('UserEmail', UserEmail);
    formData.append('UserMobile', UserMobile);
    formData.append('FbrnId', FbrnId);
    formData.append('FUserId',FUserId)
    formData.append('Fstate',Fstate)
    formData.append('UserImage',userImage)
    return this.http.post(this.urlApi.url + '/Users/' + 'UsersPost', formData);
  }

  
  public UsersDelete(check, UserId) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('UserId', UserId);
    return this.http.post(this.urlApi.url + '/Users/' + 'UsersPost', formData);
  }

}