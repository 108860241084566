import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servicesUrl } from '../servicesUrl'
import { Icompany, IcompanyStatus } from 'src/app/components/company/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient, private urlApi: servicesUrl) { }
  
  public imageUrl(): string {
    return this.urlApi.url + '/images/company/'
  }

  public getCompanyWhereId(id,lang): Observable<Icompany[]> {
    return this.http.get<Icompany[]>(this.urlApi.url + '/company/' + id +'/'+lang);
  }

  public getCompanyStatus(): Observable<IcompanyStatus[]> {
    return this.http.get<IcompanyStatus[]>(this.urlApi.url + '/company' + '/CompanyStatus');
  }

  public getCompanylang(id): Observable<Icompany[]> {
    return this.http.get<Icompany[]>(this.urlApi.url + '/company/lang/' + id);
  }

  public viewCompanyPaging(pageIndex, ddl) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('ddl', ddl);
    return this.http.post(this.urlApi.url + '/company/pagging', formData);
  }
  public CompanyPost(check, FcomId, ComName, ComActivity, ComLogo1, ComLogo2, FusrId, ComState,LangId,comEmail,comEmailPass,comEmailPort,comEmailHost) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('FcomId', FcomId);
    formData.append('ComName', ComName);
    formData.append('ComActivity', ComActivity);
    formData.append('ComLogo1', ComLogo1);
    formData.append('ComLogo2', ComLogo2);
    formData.append('FusrId', FusrId);
    formData.append('ComState', ComState);
    formData.append('LangId',LangId)
    formData.append('comEmail',comEmail)
    formData.append('comEmailPass',comEmailPass)
    formData.append('comEmailPort',comEmailPort)
    formData.append('comEmailHost',comEmailHost)
    return this.http.post(this.urlApi.url + '/company/' + 'CompanyProc', formData);
  }
}