import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { servicesUrl } from '../servicesUrl'
import { Observable } from 'rxjs';
import { Ibranches, IbranchesStatus, Icompanytype, Ilevel } from 'src/app/components/branches/model';
import { Ilanguages } from '../../components/languages/model'

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  constructor(private http: HttpClient, private urlApi: servicesUrl) { }

  public imageUrl(): string {
    return this.urlApi.url + '/images/branches/'
  }

  public getLangIsMain(): Observable<Ilanguages[]> {
    return this.http.get<Ilanguages[]>(this.urlApi.url + '/languages/getLangIsMain').pipe();
  }

  public getCompanyType(): Observable<Icompanytype[]> {
    return this.http.get<Icompanytype[]>(this.urlApi.url + '/branches/CompanyType').pipe();
  }

  public getLevel(id): Observable<Ilevel[]> {
    return this.http.get<Ilevel[]>(this.urlApi.url + '/branches/Level/' + id).pipe();
  }

  public getBrnWhereLangId(id): Observable<Ibranches[]> {
    return this.http.get<Ibranches[]>(this.urlApi.url + '/branches/GetBrn/' + id).pipe();
  }

  public getPageName(brnSys,flangId): Observable<Ibranches[]> {
    return this.http.get<Ibranches[]>(this.urlApi.url + '/branches/PageTitle/'+ brnSys+'/'+flangId).pipe();
  }

  public getBrnStatus(): Observable<IbranchesStatus[]> {
    return this.http.get<IbranchesStatus[]>(this.urlApi.url + '/branches' + '/branchesStatus').pipe();
  }

  public getMain(flevelId, brnSys, fcomId, flangId): Observable<Ibranches[]> {
    return this.http.get<Ibranches[]>(this.urlApi.url + '/branches/Main/' + flevelId + '/' + brnSys + '/' + fcomId + '/' + flangId).pipe();
  }

  public getBrnWhereId(id, lang): Observable<Ibranches[]> {
    return this.http.get<Ibranches[]>(this.urlApi.url + '/branches/' + id + '/' + lang).pipe();
  }

  public deleteBrn(check, BrnId,langId) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('BrnId', BrnId);
    formData.append('langId', langId);
    return this.http.post(this.urlApi.url + '/branches/' + 'branchesPost', formData).pipe();
  }


  public getBrnlang(id): Observable<Ilanguages[]> {
    return this.http.get<Ilanguages[]>(this.urlApi.url + '/branches/lang/' + id).pipe();
  }

  public branchesPost(check, fbrn_sys, flevelId, fcomId, langId, BrnId, BrnName, BrnTitle, BrnAddress, BrnPho1, BrnPho2, BrnEmail, BrnFax,BrnImage, Fstate, FusrId, BrnMain,BrnAbout) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('fbrn_sys', fbrn_sys);
    formData.append('flevelId', flevelId);
    formData.append('fcomId', fcomId);
    formData.append('langId', langId);
    formData.append('BrnId', BrnId);
    formData.append('BrnName', BrnName);
    formData.append('BrnTitle', BrnTitle);
    formData.append('BrnAddress', BrnAddress);
    formData.append('BrnPho1', BrnPho1);
    formData.append('BrnPho2', BrnPho2);
    formData.append('BrnEmail', BrnEmail);
    formData.append('BrnFax', BrnFax);
    formData.append('Fstate', Fstate);
    formData.append('FusrId', FusrId);
    formData.append('BrnMain', BrnMain);
    formData.append('BrnImage',BrnImage);
    formData.append('BrnAbout',BrnAbout);
    return this.http.post(this.urlApi.url + '/branches/' + 'branchesPost', formData).pipe();
  }
  public viewBrnPaging(pageIndex, ddl,BrnSys,FlangId) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('ddl', ddl);
    formData.append('BrnSys',BrnSys)
    formData.append('FlangId',FlangId)
    return this.http.post(this.urlApi.url + '/branches/pagging', formData).pipe();
  }
}