<div class="container2" [hidden]="loadPage">
  <div class="flex2">
    <div class="loader2"></div>
  </div>
  <div class="load-text2">
    <span></span>
  </div>
</div>
<!-- Home show table with filtering -->
<mat-card
  class="example-card"
  style="margin-top: 10px"
  [hidden]="showTableProd"
>
  <mat-card-header>
   
    <mat-card-title>
      {{ "Registration.ModelOrd" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-card class="example-card">
      <mat-card-header>
        <mat-card-title>
          {{ "show.Show" | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <form class="example-form">
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{ "Registration.Model" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="modelsCtrl"
                      [matAutocomplete]="automodel"
                      autocomplete="anyword"
                      [matAutocompleteTrigger]="automodel"
                      (click)="openPanelOfModels()"
                    />
                    <mat-autocomplete
                      #automodel="matAutocomplete"
                      (optionSelected)="getModelValue($event.option.value)"
                    >
                      <mat-option (click)="getAllModels()">{{
                        "show.All"  | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let srv of filteredModels | async"
                        [value]="srv.postTitle"
                      >
                        <span>{{ srv.postTitle }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <!-- <div class="col-lg-6 col-sm-12">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>{{ "show.Service" | translate }}</mat-label>
                    <input
                      type="text"
                      matInput
                      [formControl]="serviceN_jaCtrl"
                      [matAutocomplete]="autosrvNJ"
                      autocomplete="anyword"
                      [matAutocompleteTrigger]="autosrvNJ"
                      (click)="openPanelOfModels()"
                    />
                    <mat-autocomplete
                      #autosrvNJ="matAutocomplete"
                      (optionSelected)="getValue($event.option.value)"
                    >
                      <mat-option (click)="getAllModel()">{{
                        "show.All" | translate
                      }}</mat-option>
                      <mat-option
                        *ngFor="let srv of filteredServiceN_Ja | async"
                        [value]="srv.postTitle"
                      >
                        <span>{{ srv.postTitle }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div> -->
              </div>
            </form>  </div>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <p style="text-align: center">
          <button
            style="margin-left: 10px"
            style="
              bottom: 60%;
              margin-left: 10px;
              background-color: blue;
              z-index: 2;
            "
            matTooltip="عرض"
            class="float"
            mat-fab
            color="primary"
            (click)="filtering(selectedModels)"
          >
            {{ "btn.show" | translate }}
          </button>
          <button
            matTooltip="تصدير"
            mat-fab
            style="
              bottom: 50%;
              margin-left: 10px;
              background-image: url(../../../assets/images/xls.png);
              z-index: 2;
              height: 54px;
              width: 54px;
              background-repeat: no-repeat;
              background-size: 48px;
              background-color: #0c7238;
              background-position: center;
            "
            class="float"
            color="primary"
            (click)="btnExport()"
          ></button>
          <button
            mat-fab
            matTooltip="تصدير"
            style="
              bottom: 40%;
              margin-left: 10px;
              background-image: url(../../../assets/images/pdf.png);
              z-index: 2;
              height: 54px;
              width: 54px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 48px;
              background-color: #e53935;
            "
            class="float"
            color="primary" data-toggle="modal" data-target="#myModalmodel" (click)="showPanelF()"
          ></button>
        </p>
      </mat-card-actions>
    </mat-card>
    <div style="margin: 20px">
      <p style="text-align: center">
        {{ "btn.show" | translate }}

        <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </select>
      </p> <div style="overflow-x: auto; padding: 0 0 0 2px">
        <table
          id="excel_table"
          style="
            width: max-content;
            max-width: max-content;
            margin-bottom: 1.143rem;
            background-color: transparent;
          "
          class="table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th style="text-align: center" scope="col">#</th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Name" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Mobil1" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Email" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Model" | translate }}
              </th>
              <th style="text-align: center" scope="col">
                {{ "Registration.Message" | translate }}
              </th>
              <th style="padding: 3px; text-align: center" scope="col">
                {{ "our-team.Processes" | translate }}
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center !important; font-size: 12px">
            <tr *ngFor="let item of ShowData; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item.com_name }}</td>
              <td>{{ item.com_phone }}</td>
              <td>{{ item.com_email }}</td> 
              <td>{{ item.service }}</td>
              <td>{{ item.service_disc }}</td> 
              <td style="padding: 3px; width: 150px">
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="عرض"
                  (click)="showData(item.rowNumber)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                <button
                  style="margin-left: 3px"
                  matTooltipPosition="above"
                  matTooltip="تعديل"
                  (click)="showDataToUpdate(item.rowNumber)"
                  mat-mini-fab
                  color="primary"
                  aria-label="Example icon button with a menu icon"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  matTooltipPosition="above"
                  matTooltip="حذف"
                  (click)="delete(template, item.reg_srv_id)"
                  mat-mini-fab
                  color="accent"
                  aria-label="Example icon button with a plus one icon"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h2
          *ngIf="NoRecords"
          style="text-align: center; font-family: 'Droid Arabic Kufi'"
        >
          {{ "show.NoRecords" | translate }}
        </h2>
      </div>

      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngFor="let item of pageNumbers" class="page-item">
            <button (click)="getRows(item.numPage)" class="page-link">
              {{ item.namePage }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </mat-card-content>
</mat-card>
<!-- End Home show table with filtering -->
<!-- show data for updating -->
<ng-container>
  <mat-card class="example-card" *ngIf="showDataPanleUpdate">
    <mat-card-header>
      <mat-card-title>{{'Registration.ModelOrd' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'Registration.RegisteredData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Name" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="NameOrder"
                      matInput
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil1" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Phone" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Email" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Email" matInput />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field>
                    <mat-select placeholder="{{modelT}}" [(value)]="modelT">
                      <mat-option *ngFor="let realE of dataSourceModels" [value]="realE.postTitle">{{realE.postTitle}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-12 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Message" | translate }}
                    </mat-label>
                    <textarea
                      autocomplete="off"
                      [(ngModel)]="MessageNJ"
                      matInput
                    ></textarea>
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card-actions>
    <p style="text-align: center">
      <button
        *ngIf="showUpdate"
        style="margin-left: 10px"
        style="
          bottom: 30%;
          margin-left: 10px;
          background-color: green;
          z-index: 2;
        "
        class="float"
        mat-fab
        color="primary"
        (click)="update()"
      >
        {{ "btn.update" | translate }}
      </button>

      <button
        *ngIf="showcancel"
        matTooltip="الغاء العملية"
        style="
          bottom: 40%;
          margin-left: 10px;
          background-color: orangered;
          z-index: 2;
        "
        class="float"
        (click)="btnclose()"
        mat-fab
        color="primary"
      >
        {{ "btn.close" | translate }}
      </button>
    </p>
  </mat-card-actions>
</ng-container>
<!-- End show data for updating -->
<!-- show data  -->
<ng-container>
  <mat-card class="example-card" *ngIf="showDataPanle">
    <mat-card-header>
      <mat-card-title>{{'Registration.ModelOrd' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group animationDuration="1000ms">
        <mat-tab label="{{ 'Registration.RegisteredData' | translate }}">
          <div style="margin-top: 14px">
            <div class="col-lg-12">
              <div class="row" style="margin-bottom: 30; margin-top: 20px">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Name" | translate }}
                    </mat-label>
                    <input
                      autocomplete="off"
                      [(ngModel)]="NameOrder"
                      matInput readonly
                    />
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Mobil1" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Phone" matInput readonly />
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Email" | translate }}
                    </mat-label>
                    <input autocomplete="off" [(ngModel)]="Email" matInput readonly/>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label
                      >{{ "Registration.Message" | translate }}
                    </mat-label>
                    <textarea
                      autocomplete="off"
                      [(ngModel)]="MessageNJ"
                      matInput readonly
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>

  <mat-card-actions>
    <p style="text-align: center">
      <button
        *ngIf="showUpdate"
        style="margin-left: 10px"
        style="
          bottom: 30%;
          margin-left: 10px;
          background-color: green;
          z-index: 2;
        "
        class="float"
        mat-fab
        color="primary"
        (click)="update()"
      >
        {{ "btn.update" | translate }}
      </button>

      <button
        *ngIf="showcancel"
        matTooltip="الغاء العملية"
        style="
          bottom: 40%;
          margin-left: 10px;
          background-color: orangered;
          z-index: 2;
        "
        class="float"
        (click)="btnclose()"
        mat-fab
        color="primary"
      >
        {{ "btn.close" | translate }}
      </button>
    </p>
  </mat-card-actions>
</ng-container>
<!-- End show data -->

<ng-template #template>
  <div class="modal-body text-center">
    <p>هل انت متأكد من اكمال العملية المطلوبة؟</p>
    <button type="button" class="btn btn-default" (click)="confirm()">
      تأكيد
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      الغاء
    </button>
  </div>
</ng-template>

<div class="conatiner" *ngIf="showPanel">
  <div
    class="modal fade"
    id="myModalmodel" tabindex="-1"
    role="dialog"
    #childModal2="bs-modal"
    bsModal
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div style="overflow-x: auto; padding: 0 0 0 2px">
            <table
              id="pdf_model_table"
              style="
                width: max-content;
                max-width: max-content;
                margin-bottom: 1.143rem;
                background-color: transparent;
              "
              class="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    #
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Name" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Mobil1" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Email" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Model" | translate }}
                  </th>
                  <th style="text-align: center; font-size: 12px" scope="col">
                    {{ "Registration.Message" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody style="text-align: center !important; font-size: 12px">
                <tr *ngFor="let item of ShowData; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td style="font-size: 12px">{{ item.com_name }}</td>
                  <td style="font-size: 12px">{{ item.com_phone }}</td>
                  <td style="font-size: 12px">{{ item.com_email }}</td>
                  <td style="font-size: 12px">{{ item.service }}</td>
                  <td style="font-size: 12px">{{ item.service_disc }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="download()"
            data-dismiss="modal"
          >
            {{ "btn.export" | translate }}
          </button>
          <button type="button" class="btn btn-default" data-dismiss="modal">
            {{ "btn.close" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
