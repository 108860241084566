import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByName'
})
export class SearchByNamePipe implements PipeTransform {

  transform(value: any[], column: string, filterText: string) {
    if (!value || !column || !filterText) {
      return value;
  }
    return value.filter(item => item[column].toLowerCase().includes(filterText.toLowerCase()));
  
  }

}
