import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from 'ngx-bootstrap/modal';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CryptService } from 'src/app/services/crypt.service';
import { OurTeamService } from 'src/app/services/our-team/our-team.service';
import * as XLSX from 'xlsx';
import { ThemePalette } from '@angular/material/core';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css'],
})
export class ShowComponent implements OnInit {
  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Primary', completed: false, color: 'primary' },
      { name: 'Accent', completed: false, color: 'accent' },
      { name: 'Warn', completed: false, color: 'warn' },
    ],
  };

  date: any = new Date();
  fileName = 'ExcelSheet.xlsx';
  indexFirst: number;

  selectedYear = '';
  selectedServices = '';
  selectedServicesArray:any = '';
  selectedRegistration = '';
  selectedCourse = '';
  selectedDepartment = '';
  selectedSpecialization = '';
  getYear: any;

  searchService: string;
  searchCourse: string;
  searchDate: string;
  searchSpecial: string;

  ShowData: any = [];
  NoData = '';
  ServicesN_Ja: any[] = [];
  ServicesN_Ja1: any = [];
  ServicesN_Ja2: any = [];
  dataSourceServicesN_Ja2: any = [];
  dataSourceServices: any = [];
  dataSourceServicesN_Ja: any = [];
  dataSourceRegistration: any = [];
  dataSourceCountry: any = [];
  dataSourceCources: any = [];
  dataSourceDepartments: any = [];
  dataSourceSpecializations: any = [];
  mainBrn: any[] = [];
  array: any = [];
  array2: any = [];
  Program: any = [];
  translate: 'yes';

  rowsPage: number;
  TableType: number;
  pageNumbers: any = [];
  LANG_ = this.c.Decrypt(sessionStorage.getItem('syslang'));
  BRN_ = this.c.Decrypt(sessionStorage.getItem('brnId'));
  TYPE_ = '';
  TYPE_2 = '';
  parentID = '';
  procName = '';
  TYPE_Name: any;
  NoRecords: boolean = false;
  showPanel: boolean = false;
  showUpdate: boolean = false;
  showDataPanle: boolean = false;
  showcancel: boolean = false;
  showTableProd: boolean = false;
  viewDataPanle: boolean = false;
  loadPage = true;

  RegStdNo = '';
  CompName = '';
  DelegateName = '';
  City = '';
  PartnerNum = '';
  PartnersNames = '';
  Mobil1 = '';
  Mobil2 = '';
  Website = '';
  Email = '';
  Nationality = '';
  Address = '';
  InvestmentLicenseNo = '';
  CommercialRegNo = '';

  NameOrder = '';
  Mobil1NJ = '';
  IdCardNumberNJ = '';
  EmailNJ = '';
  MessageNJ = '';
  reqServicesNJ: any = '';
  reqServicesNJ2: any = [];

  stringified: any;
  serializedDate = new UntypedFormControl(new Date().toISOString());

  serviceCtrl = new UntypedFormControl();
  serviceN_jaCtrl = new UntypedFormControl();
  regestrationCtrl = new UntypedFormControl();
  countryCtrl = new UntypedFormControl();
  courcesCtrl = new UntypedFormControl();
  filteredService: Observable<string[]>;
  filteredServiceN_Ja: Observable<string[]>;
  filteredRestiration: Observable<string[]>;
  filteredCountry: Observable<string[]>;
  filteredCources: Observable<string[]>;

  year = new UntypedFormControl('');
  yearsArr = Array.from(Array(new Date().getFullYear() - 2022 + 1).keys()).map(
    (i) => 2022 + i
  );
  modalRef: BsModalRef;
  id_delete: string;
  constructor(
    private c: CryptService,
    private getData: OurTeamService,
    private _Activatedroute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private modalService: BsModalService
  ) {
    this._Activatedroute.paramMap.subscribe((params) => {
      this.TYPE_Name = this._Activatedroute.snapshot.paramMap.get('typeName');

      this.ngOnInit();
    });
  }

  myForm: UntypedFormGroup;

  //Function To Filtering Services Data
  private _filterServices(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceServices.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }
  //Function To Filtering ServicesN_Ja Data
  private _filterServicesN_Ja(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceServicesN_Ja.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }
  //Function To Filtering Registration Data
  private _filterRegistration(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceRegistration.filter((state) =>
      state.lvl_name.toLowerCase().includes(filterValue)
    );
  }
  //Function To Filtering Country Data
  private _filterCountry(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceCountry.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }

  //Function To Filtering Cources Data
  // (state) => state.postTitle.toLowerCase().indexOf(filterValue) === 0
  private _filterCourses(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.dataSourceCources.filter((state) =>
      state.postTitle.toLowerCase().includes(filterValue)
    );
  }
  searchText: string = '';
  btnclose() {
    this.showcancel = false;
    this.showDataPanle = false;
    this.showUpdate = false;
    this.clearData();
    this.showTableProd = false;
  }
  btnView() {
    // this.showDataPanle = true;
    this.viewDataPanle = true;
    this.showcancel = true;
    this.showUpdate = true;

    this.showTableProd = true;
  }
  btnUpdate() {
    this.showDataPanle = true;
    this.showcancel = true;
    this.showUpdate = true;

    this.showTableProd = true;
  }

  clearData(): void {
    // this.CompName = '';
    // this.DelegateName = '';
    // this.City = '';
    // this.PartnerNum = '';
    // this.PartnersNames = '';
    // this.Mobil1 = '';
    // this.Mobil2 = '';
    // this.Website = '';
    // this.Email = '';
    // this.Nationality = '';
    // this.Address = '';
    // this.InvestmentLicenseNo = '';
    // this.CommercialRegNo = '';
    this.NameOrder = '';
    this.Mobil1NJ = '';
    this.IdCardNumberNJ = '';
    this.EmailNJ = '';
    this.MessageNJ = '';
    this.reqServicesNJ = '';
    this.ServicesN_Ja = [];
    this.ServicesN_Ja1 = [];
    this.ServicesN_Ja2 = [];
    // this.reqServicesNJ2=[]
    this.selectedServicesArray=''
  }

  ngOnInit(): void {
    this.getRows('1');
    this.searchService = '';
    this.searchCourse = '';
    this.NoRecords = false;
    this.showDataPanle = false;
    this.showPanel = false;
    this.viewDataPanle = false;
    // this.showTableProd = false;

    // this.myForm = new FormGroup({
    //   presentDate: new FormControl(new Date().toISOString().substring(0, 10)),
    // });

    // if (this.TYPE_Name == 'services') {
    //   this.TableType = 1;
    //   this.getDropDownServicesData();
    //   this.getData
    //     .getServices('sp', '', '', '', '', '', '', '', '', this.BRN_, '')
    //     .subscribe((data) => {
    //       this.ShowData = data;
    //     });
    // } else if (this.TYPE_Name == 'courses') {
    //   this.TableType = 2;
    //   this.getDropDownCountry();
    //   this.getData
    //     .getRegStd('sp', '', '', '', '', '', '', '', '', '')
    //     .subscribe((data) => {
    //       this.ShowData = data;
    //     });
    // } else {
    //   this.TableType = 3;
    //   this.getDropDownDepartmentData();
    //   this.getData
    //     .getRegStd2(
    //       'sp',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       '',
    //       ''
    //     )
    //     .subscribe((data) => {
    //       this.ShowData = data;
    //     });
    // }
    this.getDropDownRegistrationData();
    this.getDropDownServicesN_JaData();
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  showPanelF(){
    this.showPanel=true;
    }
  getRows(num) {
    if (this.TYPE_Name == 'services') {
      this.procName = 'service';
    } else {
      this.procName = 'stdReg';
    }
    if (this.rowsPage == null) this.rowsPage = 10;
    this.getData
      .viewPaging(
        num,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        this.procName,
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.indexFirst = data['data1'][0]['displayOrder'];
        this.pageNumbers = data['data2'];
      });
    // this.ShowOrder = true
  }
  openPanelOfServices() {
    this.filteredService = this.serviceCtrl.valueChanges.pipe(
      startWith(''),
      map((reg) =>
        reg ? this._filterServices(reg) : this.dataSourceServices.slice()
      )
    );
  }
  openPanelOfServicesOfN_Ja() {
    this.filteredServiceN_Ja = this.serviceN_jaCtrl.valueChanges.pipe(
      startWith(''),
      map((reg) =>
        reg
          ? this._filterServicesN_Ja(reg)
          : this.dataSourceServicesN_Ja.slice()
      )
    );
  }
  openPanelOfRegestration() {
    this.filteredRestiration = this.regestrationCtrl.valueChanges.pipe(
      startWith(''),
      map((reg) =>
        reg
          ? this._filterRegistration(reg)
          : this.dataSourceRegistration.slice()
      )
    );
  }
  openPanelOfCountry() {
    this.filteredCountry = this.countryCtrl.valueChanges.pipe(
      startWith(''),
      map((country) =>
        country ? this._filterCountry(country) : this.dataSourceCountry.slice()
      )
    );
  }
  openPanelOfCourses() {
    this.filteredCources = this.courcesCtrl.valueChanges.pipe(
      startWith(''),
      map((course) =>
        course ? this._filterCourses(course) : this.dataSourceCources.slice()
      )
    );
  }
  getPage(event: any) {
    // this.ShowOrder = true
    if (this.TYPE_Name == 'services') {
      this.procName = 'service';
    } else {
      this.procName = 'stdReg';
    }
    this.rowsPage = event.target.value;
    this.getData
      .viewPaging(
        1,
        this.rowsPage,
        this.BRN_,
        this.LANG_,
        this.TYPE_,
        this.procName,
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data['data1'];
        this.pageNumbers = data['data2'];
      });
  }
  //Function To Export Table Data To Excel
  btnExport(): void {
    /* pass here the table id */
    let element = document.getElementById('excel_table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  //Function To Get Countries
  getDropDownCountry() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.Country',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceCountry = data;
        let arr = data as any[];
        this.parentID = arr[0]['postID'];
        this.getDropDownCourcesData(this.parentID);
      });
  }

  //Function To Get Services
  getDropDownServicesData() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.OtherServices',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceServices = data;
      });
  }
  //Function To Get ServicesNJ
  getDropDownServicesN_JaData() {
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Services2',
        'NTROOT0',
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceServicesN_Ja = data;
      });
  }
  //Function To Get Registration
  getDropDownRegistrationData() {
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.dataSourceRegistration = data;
      });
  }
  //Function To Get Cources
  getDropDownCourcesData(id: string) {
    this.dataSourceCources = [];
    this.filteredCources = of(<any>[]);
    this.getData
      .getPost(
        'getPost',
        '',
        this.LANG_,
        this.BRN_,
        'Univ.Cources',
        id,
        '',
        '-1'
      )
      .subscribe((data) => {
        this.dataSourceCources = data;
      });
    this.filteredCources = this.courcesCtrl.valueChanges.pipe(
      startWith(''),
      map((course) =>
        course ? this._filterCourses(course) : this.dataSourceCources.slice()
      )
    );
  }

  //Function To Get Department
  getDropDownDepartmentData() {
    this.getData.getPostBrn('getPost', this.LANG_, 'U').subscribe((data) => {
      this.mainBrn = data['mainBrn'];
      this.mainBrn.forEach((x) => {
        if (x.brnSys == 'Univ.Departments') {
          this.dataSourceDepartments.push({
            BrnId: x.brnId,
            DeptName: x.brnName,
          });
        }
      });
    });
  }
  getAll() {
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data;
      });
    this.NoRecords = false;
  }
  getAllServices() {
    this.NoRecords = false;
    this.getData
      .getServices('sp', '', '', '', '', '', '', '', '', this.BRN_, '')
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getAllServicesNJa() {
    this.NoRecords = false;
    this.getData
      .getPost('getPost', '', this.LANG_, '', 'Services2', '', '', '-1')
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getAllRegestration() {
    this.NoRecords = false;
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.ShowData = data;
      });
  }
  getAllCountries_Courses() {
    this.getData
      .getRegStd('sp', '', '', '', '', '', '', '', '', '')
      .subscribe((data) => {
        this.ShowData = data;
      });
    this.NoRecords = false;
  }
  getValue(value: any) {
    this.selectedServices = value;
  }
  getRegValue(value: any) {
    this.selectedRegistration = value;
    
  }
  getValueOfCourse(value: any) {
    this.selectedCourse = value;
  }
  //Function To Get Specialization
  onChangeDepartment() {
    this.array = [];
    this.array2 = [];

    this.getData.getPostBrn('getPost', this.LANG_, 'U').subscribe((data) => {
      this.mainBrn = data['mainBrn'];

      this.getData
        .getPost(
          'getPostDetails',
          '',
          this.LANG_,
          '',
          'Univ.Departments.Home.Program',
          '',
          '',
          '-1'
        )
        .subscribe((data2) => {
          let arr2 = data2 as [];

          arr2.forEach((el2) => {
            if (this.selectedDepartment == el2['brnID']) {
              this.array.push({
                postTitle: el2['postTitle'],
              });
            }
          });
        });
      this.dataSourceSpecializations = this.array;
    });
    this.array = [];
  }

  //Function To Get The Date From DatePiker
  updateDate(event: any) {
    this.searchDate = '';
    this.stringified = event.value;
    if (this.stringified.getDate() < 10) {
      this.searchDate =
        this.stringified.getFullYear() +
        '-' +
        (this.stringified.getMonth() + 1) +
        '-0' +
        this.stringified.getDate();
    } else {
      this.searchDate =
        this.stringified.getFullYear() +
        '-' +
        (this.stringified.getMonth() + 1) +
        '-' +
        this.stringified.getDate();
    }
  }

  // filtering(title: any, year: string) {
  //   if (this.TYPE_Name == 'services') {
  //     this.getData
  //       .getServices('sp', '', '', '', '', '', '', '', '', '', '')
  //       .subscribe((data) => {
  //         this.ShowData = data;
  //         let records = [];
  //         this.ShowData.filter((record) => record.service === title).forEach(
  //           (record) => records.push(record)
  //         );
  //         this.ShowData = [];
  //         if (records.length == 0) {
  //           this.NoRecords = true;
  //         } else {
  //           records.forEach((el2) => {
  //             this.NoRecords = false;
  //             this.ShowData.push({
  //               com_name: el2['com_name'],
  //               com_domain: el2['com_domain'],
  //               com_phone: el2['com_phone'],
  //               com_email: el2['com_email'],
  //               service: el2['service'],
  //               city: el2['city'],
  //               service_disc: el2['service_disc'],
  //             });
  //           });
  //         }
  //       });
  //   } else if (this.TYPE_Name == 'courses') {
  //     this.getData
  //       .getRegStd('sp', '', '', '', '', '', '', '', '', '')
  //       .subscribe((data) => {
  //         this.ShowData = data;
  //         let records = [];
  //         if (title != '' && year != undefined) {

  //           this.ShowData.filter(
  //             (record) => record.lvl_name === title && record.lvl_date == year
  //           ).forEach((record) => records.push(record));
  //           this.ShowData = [];
  //           if (records.length == 0) {
  //             this.NoRecords = true;
  //           } else {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 lvl_name: el2['lvl_name'],
  //                 lvl_date: el2['lvl_date'],
  //                 std_name: el2['std_name'],
  //                 std_phone: el2['std_phone'],
  //                 std_email: el2['std_email'],
  //               });
  //             });
  //           }
  //         } else if (title != '' && year == undefined) {
  //           this.ShowData.filter((record) => record.lvl_name === title).forEach(
  //             (record) => records.push(record)
  //           );
  //           this.ShowData = [];
  //           if (records.length == 0) {
  //             this.NoRecords = true;
  //           } else {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 lvl_name: el2['lvl_name'],
  //                 lvl_date: el2['lvl_date'],
  //                 std_name: el2['std_name'],
  //                 std_phone: el2['std_phone'],
  //                 std_email: el2['std_email'],
  //               });
  //             });
  //           }
  //         } else if (title == '' && year != undefined) {
  //           this.ShowData.filter((record) => record.lvl_date == year).forEach(
  //             (record) => records.push(record)
  //           );
  //           this.ShowData = [];
  //           if (records.length == 0) {
  //             this.NoRecords = true;
  //           } else {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 lvl_name: el2['lvl_name'],
  //                 lvl_date: el2['lvl_date'],
  //                 std_name: el2['std_name'],
  //                 std_phone: el2['std_phone'],
  //                 std_email: el2['std_email'],
  //               });
  //             });
  //           }
  //         } else {
  //           alert('Enter');
  //           this.getAllCountries_Courses();
  //         }
  //       });
  //   } else {
  //     this.getData
  //       .getRegStd2(
  //         'sp',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         '',
  //         ''
  //       )
  //       .subscribe((data) => {
  //         this.ShowData = data;
  //         let records = [];
  //         if (title != '' && year != '') {
  //           this.ShowData.filter(
  //             (record) =>
  //               record.std_lvl_main === title &&
  //               record.c_date.slice(0, 4) == year
  //           ).forEach((record) => records.push(record));
  //           this.ShowData = [];
  //           if (records.length > 0) {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 std_name: el2['std_name'],
  //                 std_gender: el2['std_gender'],
  //                 std_nationality: el2['std_nationality'],
  //                 std_place_birthday: el2['std_place_birthday'],
  //                 std_date_birthday: el2['std_date_birthday'],
  //                 std_type_qualification: el2['std_type_qualification'],
  //                 std_average: el2['std_average'],
  //                 std_date_qualification: el2['std_date_qualification'],
  //                 std_place_qualification: el2['std_place_qualification'],
  //                 std_governorate: el2['std_governorate'],
  //                 std_school: el2['std_school'],
  //                 std_place_issue: el2['std_place_issue'],
  //                 std_date_issue: el2['std_date_issue'],
  //                 std_type_study: el2['std_type_study'],
  //                 std_phone: el2['std_phone'],
  //                 std_address: el2['std_address'],
  //                 std_email: el2['std_email'],
  //                 std_lvl_main: el2['std_lvl_main'],
  //                 std_type_id_card: el2['std_type_id_card'],
  //                 std_id_card_number: el2['std_id_card_number'],
  //                 std_palce_id_card: el2['std_palce_id_card'],
  //               });
  //             });
  //           } else {
  //             this.NoRecords = true;
  //           }
  //         } else if (title != '' && year == '') {
  //           this.ShowData.filter(
  //             (record) => record.std_lvl_main === title
  //           ).forEach((record) => records.push(record));
  //           this.ShowData = [];
  //           if (records.length > 0) {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 std_name: el2['std_name'],
  //                 std_gender: el2['std_gender'],
  //                 std_nationality: el2['std_nationality'],
  //                 std_place_birthday: el2['std_place_birthday'],
  //                 std_date_birthday: el2['std_date_birthday'],
  //                 std_type_qualification: el2['std_type_qualification'],
  //                 std_average: el2['std_average'],
  //                 std_date_qualification: el2['std_date_qualification'],
  //                 std_place_qualification: el2['std_place_qualification'],
  //                 std_governorate: el2['std_governorate'],
  //                 std_school: el2['std_school'],
  //                 std_place_issue: el2['std_place_issue'],
  //                 std_date_issue: el2['std_date_issue'],
  //                 std_type_study: el2['std_type_study'],
  //                 std_phone: el2['std_phone'],
  //                 std_address: el2['std_address'],
  //                 std_email: el2['std_email'],
  //                 std_lvl_main: el2['std_lvl_main'],
  //                 std_type_id_card: el2['std_type_id_card'],
  //                 std_id_card_number: el2['std_id_card_number'],
  //                 std_palce_id_card: el2['std_palce_id_card'],
  //               });
  //             });
  //           } else {
  //             this.NoRecords = true;
  //           }
  //         } else if (title == '' && year != '') {
  //           this.ShowData.filter(
  //             (record) => record.c_date.slice(0, 4) == year
  //           ).forEach((record) => records.push(record));
  //           this.ShowData = [];
  //           if (records.length > 0) {
  //             this.NoRecords = false;
  //             records.forEach((el2) => {
  //               this.ShowData.push({
  //                 std_name: el2['std_name'],
  //                 std_gender: el2['std_gender'],
  //                 std_nationality: el2['std_nationality'],
  //                 std_place_birthday: el2['std_place_birthday'],
  //                 std_date_birthday: el2['std_date_birthday'],
  //                 std_type_qualification: el2['std_type_qualification'],
  //                 std_average: el2['std_average'],
  //                 std_date_qualification: el2['std_date_qualification'],
  //                 std_place_qualification: el2['std_place_qualification'],
  //                 std_governorate: el2['std_governorate'],
  //                 std_school: el2['std_school'],
  //                 std_place_issue: el2['std_place_issue'],
  //                 std_date_issue: el2['std_date_issue'],
  //                 std_type_study: el2['std_type_study'],
  //                 std_phone: el2['std_phone'],
  //                 std_address: el2['std_address'],
  //                 std_email: el2['std_email'],
  //                 std_lvl_main: el2['std_lvl_main'],
  //                 std_type_id_card: el2['std_type_id_card'],
  //                 std_id_card_number: el2['std_id_card_number'],
  //                 std_palce_id_card: el2['std_palce_id_card'],
  //               });
  //             });
  //           } else {
  //             this.NoRecords = true;
  //           }
  //         } else {
  //           this.getAll();
  //         }
  //       });
  //   }
  // }
  filtering(name: any, service: string) {
 
      this.getData
        .getRegStd2('sp', '', '', '', '', '', '', '', '', '','','','','','','','','','','','','','','','','','')
        .subscribe((data) => {
          this.ShowData = data;
          let records = [];
          if (name != '' && service != undefined) {

            this.ShowData.filter(
              (record) => record.lvl_name === name && record.std_gender == service
            ).forEach((record) => records.push(record));
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  std_phone: el2['std_phone'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_email: el2['std_email'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                });
              });
            }
          } else if (name != '' && service == undefined) {
            this.ShowData.filter((record) => record.lvl_name === name).forEach(
              (record) => records.push(record)
            );
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  std_phone: el2['std_phone'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_email: el2['std_email'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                });
              });
            }
          } else if (name == '' && service != undefined) {
            this.ShowData.filter((record) => record.std_gender == service).forEach(
              (record) => records.push(record)
            );
            this.ShowData = [];
            if (records.length == 0) {
              this.NoRecords = true;
            } else {
              this.NoRecords = false;
              records.forEach((el2) => {
                this.ShowData.push({
                  lvl_name: el2['lvl_name'],
                  std_phone: el2['std_phone'],
                  std_id_card_number: el2['std_id_card_number'],
                  std_email: el2['std_email'],
                  std_gender: el2['std_gender'],
                  std_nationality: el2['std_nationality'],
                });
              });
            }
          } else {
            this.getAllServicesNJa();
          }
        });
    
  }
  // filtering(title: any) {
  //   this.getData
  //     .getRegStd2(
  //       'sp',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       '',
  //       ''
  //     )
  //     .subscribe((data) => {
  //       this.ShowData = data;
  //       let records = [];
  //       this.ShowData.filter((record) => record.lvl_name === title).forEach(
  //         (record) => records.push(record)
  //       );
  //       this.ShowData = [];
  //       if (records.length == 0) {
  //         this.NoRecords = true;
  //       } else {
  //         records.forEach((el2) => {
  //           this.NoRecords = false;
  //           this.ShowData.push({
  //             lvl_name: el2['lvl_name'],
  //             std_name: el2['std_name'],
  //             std_governorate: el2['std_governorate'],
  //             std_average: el2['std_average'],
  //             std_date_birthday: el2['std_date_birthday'],
  //             std_palce_id_card: el2['std_palce_id_card'],
  //             std_phone: el2['std_phone'],
  //             std_place_qualification: el2['std_phone'],
  //             std_email: el2['std_phone'],
  //             std_nationality: el2['std_phone'],
  //             std_address: el2['std_phone'],
  //             std_id_card_number: el2['std_phone'],
  //             std_school: el2['std_phone'],
  //           });
  //         });
  //       }
  //     });
  // }

  showDataToUpdate(rowNumber: any) {
    this.loadPage = false;
    this.clearData();
    this.btnUpdate();
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data: any) => {
        this.RegStdNo = data[rowNumber - 1]['reg_std_id'];
        this.CompName = data[rowNumber - 1]['lvl_name'];
        this.DelegateName = data[rowNumber - 1]['std_name'];
        this.City = data[rowNumber - 1]['std_governorate'];
        this.PartnerNum = data[rowNumber - 1]['std_average'];
        this.PartnersNames = data[rowNumber - 1]['std_date_birthday'];
        this.Mobil1 = data[rowNumber - 1]['std_palce_id_card'];
        this.Mobil2 = data[rowNumber - 1]['std_phone'];
        this.Website = data[rowNumber - 1]['std_place_qualification'];
        this.Email = data[rowNumber - 1]['std_email'];
        this.Nationality = data[rowNumber - 1]['std_nationality'];
        this.Address = data[rowNumber - 1]['std_address'];
        this.InvestmentLicenseNo = data[rowNumber - 1]['std_id_card_number'];
        this.CommercialRegNo = data[rowNumber - 1]['std_school'];
        this.loadPage = true;
      });
  }
  viewData(rowNumber: any) {
    this.loadPage = false;
    this.clearData();
    this.btnView();
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data: any) => {
        this.RegStdNo = data[rowNumber - 1]['reg_std_id'];
        this.NameOrder = data[rowNumber - 1]['lvl_name'];
        this.Mobil1NJ = data[rowNumber - 1]['std_phone'];
        this.IdCardNumberNJ = data[rowNumber - 1]['std_id_card_number'];
        this.EmailNJ = data[rowNumber - 1]['std_email'];
        this.MessageNJ = data[rowNumber - 1]['std_nationality'];
        this.reqServicesNJ = data[rowNumber - 1]['std_gender'];

        this.reqServicesNJ2 = this.reqServicesNJ.split('<br>');
        this.dataSourceServicesN_Ja.forEach((el) => {
          this.ServicesN_Ja1.push({
            postTitle: el['postTitle'],
          });
        });
        this.reqServicesNJ2.forEach((el) => {
          this.ServicesN_Ja2.push({
            postTitle: el,
          });
        });
        
        console.log("ServicesN_Ja2",this.ServicesN_Ja2);
        var isFound = false;
        this.ServicesN_Ja1.forEach((el) => {
          isFound = false;
          this.ServicesN_Ja2.forEach((el2) => {
            if (el['postTitle'] == el2['postTitle']) {
              isFound = true;
            }
          });

          if (isFound) {
            this.ServicesN_Ja.push({
              postTitle: el['postTitle'],
              checked: true,
            });
          } else {
            this.ServicesN_Ja.push({
              postTitle: el['postTitle'],
              checked: false,
            });
          }
        });
        this.loadPage = true;
      });
  }
  showDataNJToUpdate(rowNumber: any) {
    this.loadPage = false;
    this.clearData();
    this.btnUpdate();
    this.getData
      .getRegStd2(
        'sp',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data: any) => {
        this.RegStdNo = data[rowNumber - 1]['reg_std_id'];
        this.NameOrder = data[rowNumber - 1]['lvl_name'];
        this.Mobil1NJ = data[rowNumber - 1]['std_phone'];
        this.IdCardNumberNJ = data[rowNumber - 1]['std_id_card_number'];
        this.EmailNJ = data[rowNumber - 1]['std_email'];
        this.MessageNJ = data[rowNumber - 1]['std_nationality'];
        this.reqServicesNJ = data[rowNumber - 1]['std_gender'];
console.log("reqServicesNJ",this.reqServicesNJ);

        this.reqServicesNJ2 = this.reqServicesNJ.split('<br>');
        this.dataSourceServicesN_Ja.forEach((el) => {
          this.ServicesN_Ja1.push({
            postTitle: el['postTitle'],
          });
        });
        console.log("reqServicesNJ2",this.reqServicesNJ2);
        this.reqServicesNJ2.forEach((el) => {
          this.ServicesN_Ja2.push({
            postTitle: el,
          });
        });
        
        console.log("ServicesN_Ja2",this.ServicesN_Ja2);
        var isFound = false;
        this.ServicesN_Ja1.forEach((el) => {
          isFound = false;
          this.ServicesN_Ja2.forEach((el2) => {
            if (el['postTitle'] == el2['postTitle']) {
              isFound = true;
            }
          });

          if (isFound) {
            this.ServicesN_Ja.push({
              postTitle: el['postTitle'],
              checked: true,
            });
          } else {
            this.ServicesN_Ja.push({
              postTitle: el['postTitle'],
              checked: false,
            });
          }
        });
        console.log("ServicesN_Ja",this.ServicesN_Ja);
        this.loadPage = true;
      });
  }
  checkedValues: string[] = [];
  // onCheckboxChange(event: any, item: any) {   
  //   if ( item.checked) {
  //     this.checkedValues.push(item.postTitle);
  //     this.selectedServicesArray = this.checkedValues.join('<br>');
  //   } else {
  //     const index = this.checkedValues.indexOf(item.postTitle);
  //     if (index >= 0) {
  //       this.checkedValues.splice(index, 1);
  //     }
  //     this.selectedServicesArray = this.checkedValues.join('<br>');
  //   }
    
  // }
  checked( item: any) { 
    this.selectedServicesArray=[] 
    this.checkedValues=[] 
    item.forEach(el => {
      
      if ( el.checked) {
        this.checkedValues.push(el.postTitle);
        this.selectedServicesArray = this.checkedValues.join('<br>');
        
      }     
    }); 
    console.log("this.selectedServicesArray",this.selectedServicesArray);
  }
  update(): void {
    this.loadPage = false;
    this.showUpdate = false;
    this.showcancel = false;
    this.getData
      .getRegStd2(
        'u',
        this.RegStdNo,
        this.CompName,
        this.DelegateName,
        '',
        this.Nationality,
        this.PartnersNames,
        '',
        '',
        this.PartnerNum,
        '',
        this.Website,
        this.City,
        this.CommercialRegNo,
        '',
        '',
        '',
        this.Address,
        '',
        '',
        this.InvestmentLicenseNo,
        this.Mobil1,
        this.Mobil2,
        this.Email,
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.getDataProd();
        this.clearData();
        this.btnclose();
        this._snackBar.open(data[0]['column1'], 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left',
        });
        this.loadPage = true;
      });
  }

  updateNJ(): void {
    this.loadPage = false;
    this.showUpdate = false;
    this.showcancel = false;
    // if(this.selectedServicesArray==null || this.selectedServicesArray.length==0){ 
    //   this.selectedServicesArray=this.reqServicesNJ2.join('<br>');
    // }
    // else{
      
    //   this.selectedServicesArray=this.reqServicesNJ2.join('<br>')+'<br>'+this.checkedValues.join('<br>');
    // }
    // console.log("this.reqServicesNJ2",this.reqServicesNJ2.join('<br>'));
    // console.log("this.selectedServicesArray",this.selectedServicesArray);
    this.checked(this.ServicesN_Ja)
    console.log("service",this.ServicesN_Ja);
    
    this.getData
      .getRegStd2(
        'u',
        this.RegStdNo,
        this.NameOrder,
        '',
        this.selectedServicesArray,
        this.MessageNJ,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        this.IdCardNumberNJ,
        '',
        this.Mobil1NJ,
        this.EmailNJ,
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.getDataProd();
        this.clearData();
        this.btnclose();
        this._snackBar.open(data[0]['column1'], 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left',
        });
      });
        this.loadPage = true;
  }

  getDataProd(): void {
    this.getRows('1');
    this.loadPage = true;
  }

  delete(template: TemplateRef<any>, id: any): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.id_delete = id;
  }

  confirm(): void {
    this.loadPage = false;
    this.getData
      .getRegStd2(
        'd',
        this.id_delete,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      )
      .subscribe((data) => {
        this.getDataProd();
        this.clearData();

        this._snackBar.open(data[0]['column1'], 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left',
        });
        this.loadPage = true;
      });
    this.id_delete = '';
    this.modalRef.hide();
  }
  message = '';
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }
  // @ViewChild('content') content: ElementRef;
  @ViewChild('childModal') public childModal: ModalDirective;

  public hideChildModal(): void {
    this.childModal.hide();
  }

  download() {
    var element = document.getElementById('pdf_table');
    var opt = {
      margin: 1,
      filename: 'registerOrder.pdf',
      html2canvas: { scale: 2 },
      image: { type: 'jpeg', quality: 0.98 },
      jsPDF: {
        unit: 'mm',
        format: 'A2',
        orientation: 'landscape',
        header: 'My Header',
      },
    };

    html2pdf().from(element).set(opt).save();
  }
}
