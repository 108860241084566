import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
  <div class="form-group row" [formGroup]="form">
    <label class="col-md-3 form-control-label" [attr.for]="field.label">
      {{field.label}}
      <strong class="text-danger" *ngIf="field.required && form.get(field.name).invalid">*</strong>
    </label>
    <div class="col-md-9" [ngSwitch]="field.type">
      <textbox  *ngSwitchCase="'NT_text'"  [field]="field"  [form]="form.get(field.name)"></textbox>
      <textbox *ngSwitchCase="'date'" [field]="field" [form]="form.get(field.name)"></textbox>
      <textbox *ngSwitchCase="'email'" [field]="field" [form]="form.get(field.name)"></textbox>
      <textbox *ngSwitchCase="'tel'" [field]="field" [form]="form.get(field.name)"></textbox>
      <textbox *ngSwitchCase="'textarea'" [field]="field" [form]="form.get(field.name)"></textbox>
      <Icons *ngSwitchCase="'NT_icons'" [field]="field" [form]="form.get(field.name)"></Icons>
      <dropdown *ngSwitchCase="'NT_ddl'" [field]="field" [form]="form.get(field.name)"></dropdown>
      <checkbox *ngSwitchCase="'NT_check'" [field]="field" [form]="form.get(field.name)"></checkbox>
      <radio *ngSwitchCase="'NT_radio'" [field]="field" [form]="form.get(field.name)"></radio>
      <file *ngSwitchCase="'NT_file'" [field]="field" [form]="form.get(field.name)"></file>
      <div class="alert alert-danger my-1 p-2 fadeInDown animated" *ngIf="!isValid && isDirty">{{field.label}} مطلوب</div>
    </div>
  </div>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field:any;
  @Input() form:any;
  
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnInit() {
  }

}
