<div class="container2" [hidden]="loadPage">
  <div class="flex2">
    <div class="loader2"></div>
  </div>
  <div class="load-text2">
    <span> </span>
  </div>
</div>

<ng-container *ngIf="showDataPanle">
  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>{{ namebrn }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div style="margin-top: -20px">
        <div class="col-lg-12">
          <div class="row" style="margin-bottom: 30; margin-top: 20px">
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%; margin: 0; margin-right: 10px"
                  >
                    <mat-label>{{ "branches.Lang" | translate }}</mat-label>
                    <mat-select
                      matNativeControl
                      [(value)]="selectedLang"
                      [disabled]="disableSelectLang"
                    >
                      <mat-option
                        *ngFor="let Lang of languages"
                        [value]="Lang.LangId"
                      >
                        {{ Lang.LangName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%; margin: 0; margin-right: 10px"
                  >
                    <mat-label>{{ "branches.status" | translate }}</mat-label>
                    <mat-select [(value)]="selected">
                      <mat-option
                        *ngFor="let Statu of Status"
                        [value]="Statu.IntStatId"
                      >
                        {{ Statu.StatNameAr }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12 d-none">
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%; margin: 0; margin-right: 10px"
                  >
                    <mat-label>{{ "branches.type" | translate }}</mat-label>
                    <mat-select
                      [disabled]="disableSelectType"
                      (valueChange)="onChangeComType()"
                      matNativeControl
                      [(value)]="selectedcompanyType"
                    >
                      <mat-option
                        *ngFor="let comType of companyType"
                        [value]="comType.SysTypeId"
                      >
                        {{ comType.SysTypeNameAr }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12 d-none">
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%; margin: 0; margin-right: 10px"
                  >
                    <mat-label>{{ "branches.Level" | translate }}</mat-label>
                    <mat-select
                      [disabled]="disableSelectLevel"
                      (valueChange)="onChangeLevel()"
                      matNativeControl
                      [(value)]="selectedLevel"
                    >
                      <mat-option
                        *ngFor="let level of Level"
                        [value]="level.LevelId"
                      >
                        {{ level.LevelName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12 d-none">
                  <mat-form-field
                    appearance="fill"
                    style="width: 100%; margin: 0; margin-right: 10px"
                  >
                    <mat-label>{{ "branches.Main" | translate }}</mat-label>
                    <mat-select
                      matNativeControl
                      [disabled]="disableSelect"
                      [(value)]="selectedMain"
                    >
                      <mat-option
                        *ngFor="let main of Main"
                        [value]="main.BrnId"
                      >
                        {{ main.BrnName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnName" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnName"
                      autocomplete="off"
                      type="text"
                      matInput
                      placeholder="{{ 'branches.BrnName' | translate }}"
                    />
                    <mat-icon style="color: #374bab" matSuffix>create</mat-icon>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12 d-none">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnTitle" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnTitle"
                      autocomplete="off"
                      type="text"
                      matInput
                      placeholder="{{ 'branches.BrnTitle' | translate }}"
                    />
                    <mat-icon style="color: #374bab" matSuffix>edit</mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{
                      "branches.BrnAddress" | translate
                    }}</mat-label>
                    <input
                      [(ngModel)]="BrnAddress"
                      autocomplete="off"
                      type="text"
                      matInput
                      placeholder="{{ 'branches.BrnAddress' | translate }}"
                    />
                    <mat-icon style="color: #374bab" matSuffix
                      >location_on</mat-icon
                    >
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnPho1" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnPho1"
                      autocomplete="off"
                      type="tel"
                      matInput
                      placeholder="777-777-777"
                    />
                    <mat-icon style="color: #374bab" matSuffix
                      >phone_android</mat-icon
                    >
                  </mat-form-field>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnPho2" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnPho2"
                      autocomplete="off"
                      type="tel"
                      matInput
                      placeholder="01-111-111"
                    />
                    <mat-icon style="color: #374bab" matSuffix>phone</mat-icon>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnEmail" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnEmail"
                      autocomplete="off"
                      type="email"
                      matInput
                      placeholder="info.com"
                    />
                    <mat-icon style="color: #374bab" matSuffix>email</mat-icon>
                  </mat-form-field>
                </div>

                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>{{ "branches.BrnFax" | translate }}</mat-label>
                    <input
                      [(ngModel)]="BrnFax"
                      autocomplete="off"
                      type="email"
                      matInput
                      placeholder="555-555-1234"
                    />
                    <mat-icon style="color: #374bab" matSuffix>
                      local_print_shop</mat-icon
                    >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="row">
                <div
                  class="col-lg-12 col-sm-12 col-xs-12"
                  style="text-align: center; margin-top: 0px"
                >
                  <input
                    hidden
                    accept="image/*"
                    (change)="fileChangeEvent($event)"
                    #fileInput
                    type="file"
                    id="file"
                  />

                  <img
                    [src]="croppedImage"
                    (click)="fileInput.click()"
                    width="200"
                    height="200"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 d-none">
              <div class="row">
                <div
                  class="col-lg-12 col-sm-12 col-xs-12"
                  style="margin-top: 0; margin-bottom: 3px; margin-right: 10px"
                >
                  <mat-label>{{ "branches.BrnDetails" | translate }}</mat-label>
                  <ckeditor
                    [config]="editorConfig"
                    [editor]="Editor"
                    [(ngModel)]="BrnDetails"
                  >
                  </ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <p style="text-align: center">
        <button
          style="margin-left: 10px"
          *ngIf="showSave"
          matTooltipPosition="left"
          matTooltip="حفظ البيانات"
          class="btn_float"
          mat-fab
          color="primary"
          (click)="save()"
        >
          حفظ
        </button>

        <button
          *ngIf="showUpdate"
          style="margin-left: 10px"
          class="btn_float"
          mat-fab
          color="primary"
          (click)="update()"
        >
          تعديل
        </button>

        <button
          matTooltipPosition="left"
          matTooltip="الغاء العملية"
          class="floatcancel"
          (click)="btnclose()"
          mat-fab
          color="primary"
        >
          الغاء
        </button>
      </p>
    </mat-card-actions>
  </mat-card>
</ng-container>

<mat-card
  class="example-card"
  style="margin-top: 10px"
  [hidden]="showTableNews"
>
  <mat-card-header>
    <mat-card-title
      >{{ "branches.ShowPage" | translate }} {{ namebrn }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div style="margin: 20px">
      <p style="text-align: center">
        <button
          *ngIf="showNew"
          style="
            margin-top: -11px;
            margin-bottom: 10px;
            margin-right: 2px;
            float: left;
          "
          mat-raised-button
          color="primary"
          (click)="btnNew()"
        >
          {{ "btn.new" | translate }}
          <mat-icon
            style="
              margin-bottom: -5px;
              margin-right: 5px;
              margin-top: 0px;
              margin-left: -6px;
            "
            >add</mat-icon
          >
        </button>
        {{ "btn.show" | translate }}
        <select (change)="getPage($event)">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </p>

      <table
        style="
          width: 100%;
          max-width: 100%;
          margin-bottom: 1.143rem;
          background-color: transparent;
        "
        class="table table-bordered table-striped"
      >
        <thead>
          <tr>
            <th style="padding: 3px; text-align: center" scope="col">#</th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "branches.BrnName" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "branches.status" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "branches.Lang" | translate }}
            </th>
            <th style="padding: 3px; text-align: center" scope="col">
              {{ "branches.Processes" | translate }}
            </th>
          </tr>
        </thead>
        <tbody style="text-align: center !important">
          <tr *ngFor="let item of tableNews; let i = index">
            <th style="padding: 3px" scope="row">{{ i + 1 }}</th>
            <td style="padding: 3px">{{ item.brnName }}</td>
            <td style="padding: 3px">{{ item.namestatAr }}</td>
            <td style="padding: 3px">{{ item.langId }}</td>
            <td style="padding: 3px; width: 150px">
              <button
                style="margin-left: 3px"
                matTooltipPosition="above"
                matTooltip="تعديل"
                (click)="showData(item.brnId, item.langId)"
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a menu icon"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                style="margin-left: 3px"
                matTooltip="  لغة جديدة لهذة الفرع"
                (click)="showNewslang(item.brnId, item.langId)"
                mat-mini-fab
                mat-mini-fab
                color="primary"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>translate</mat-icon>
              </button>
              <button
                matTooltipPosition="above"
                matTooltip="حذف"
                (click)="delete(template, item.brnId, item.langId)"
                mat-mini-fab
                color="accent"
                aria-label="Example icon button with a plus one icon"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngFor="let item of pageNumbers" class="page-item">
            <button (click)="getRows(item.numPage)" class="page-link">
              {{ item.namePage }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </mat-card-content>
</mat-card>
<ng-container>
  <div
    [hidden]="showImage"
    id="exampleModalLive"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLiveLabel"
    style="padding-right: 17px; display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLiveLabel">اقتصاص الصورة</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="5"
            [resizeToWidth]="1920"
            [cropperMinWidth]="1920"
            [canvasRotation]="canvasRotation"
            [onlyScaleDown]="true"
            [imageURL]="imgUrl"
            format="png"
            [imageQuality]="30"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"
          >
          </image-cropper>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="cut()">
            اقتصاص
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            (click)="cut()"
            data-dismiss="modal"
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #template>
  <div class="modal-body text-center">
    <p>هل انت متأكد من اكمال العملية المطلوبة؟</p>
    <button type="button" class="btn btn-default" (click)="confirm()">
      تأكيد
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      الغاء
    </button>
  </div>
</ng-template>
