import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CryptService } from 'src/app/services/crypt.service';
import { servicesUrl } from 'src/app/services/servicesUrl';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  pass1: string = ''
  pass2: string = ''
  pass3: string = ''
  constructor(private _snackBar: MatSnackBar, private http: HttpClient, private _url: servicesUrl, private c: CryptService) { }

  ngOnInit(): void {
  }
  change(): void {
    if (this.Validation()) {
      const formData = new FormData();
      formData.append('UserId',this.c.Decrypt(sessionStorage.getItem('usrId')));
      formData.append('UserPass', this.pass2);
      formData.append('UserPassOld', this.pass1);
      this.http.post(this._url.url + "/login/changePassWord", formData).subscribe(data=>{
        let arr = data as any[]

        if (arr[0]["column2"] == 'success') {          
          this._snackBar.open(
          
            arr[0]["column1"],
            'حسنا'
            , {
            duration: 5000,
            horizontalPosition: 'left'
          });
          this.pass1 = ''
          this.pass2 = ''
          this.pass3 = ''
       
        }
        else{
          this._snackBar.open(
            arr[0]["column1"],
            'حسنا'
            , {
            duration: 5000,
            horizontalPosition: 'left'
          });

        }
      })
    }
  }

    Validation(): boolean {

      if (this.pass1 == '' || this.pass1 == null) {
        this._snackBar.open('الرجاء ادخال كلمة المرور الحالية اولا', 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left'
        });
        return false;
      }

      else if (this.pass2 == '' || this.pass2 == null) {
        this._snackBar.open('الرجاء ادخال كلمة المرور الجديدة اولا', 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left'
        });
        return false;
      }

      else if (this.pass3 == '' || this.pass3 == null) {
        this._snackBar.open('الرجاء ادخال تاكيد كلمة المرور اولا', 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left'
        });
        return false;
      }
      else if (this.pass2 != this.pass3) {
        this._snackBar.open('كلمة المرور غير متطابقة', 'حسنا', {
          duration: 5000,
          horizontalPosition: 'left'
        });
        this.pass3 = ''
        return false;
      }

      return true;
    }
  }

