<div class="login-page">
    <p>تغيير كلمة المرور المستخدم</p>
    <div class="form">
        <div class="login-form">
            <input type="password" (keyup.enter)="change()" [(ngModel)]="pass1" autocomplete="off" placeholder="كلمة المرور الحالية" />
            <input type="password" (keyup.enter)="change()" [(ngModel)]="pass2" autocomplete="off" placeholder="كلمة المرور" />
            <input type="password" (keyup.enter)="change()" [(ngModel)]="pass3" autocomplete="off" placeholder="تاكيد كلمة المرور " />
            <button (click)="change()">تغيير كلمة المرور</button>
        </div>
    </div>
</div>